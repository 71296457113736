<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb />
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12">
      <b-tabs content-class="mt-3" justified lazy>
        <b-tab title="Overview">
          <overview />
        </b-tab>
        <b-tab title="Application" active>
          <Application />
        </b-tab>
        <b-tab title="Inventory">
          <Inventory />
        </b-tab>
        <b-tab title="Notifications" disabled>
          <p class="hp-p1-body"></p>
        </b-tab>
      </b-tabs>
    </b-col>

    <!-- Approve Modal -->
    <b-modal
      id="approveApplicationModal"
      ref="approveApplicationModal"
      title="Approve Application"
      size="md"
      hide-footer
      hide-header-close
      centered
    >
      <b-row>
        <b-col cols="12">
          <p>Are you sure you want to approve this application?</p>
        </b-col>
        <b-col cols="12" class="text-right">
          <b-button
            variant="primary"
            class="mr-1"
            id="approveApplicationModalConfirm"
            @click="approveApplicationModalConfirm"
            >Confirm</b-button
          >
          <b-button
            variant="outline-primary"
            id="approveApplicationModalCancel"
            @click="approveApplicationModalCancel"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <!-- Reject Modal -->
    <b-modal
      id="rejectApplicationModal"
      ref="rejectApplicationModal"
      title="Reject Application"
      size="md"
      hide-footer
      hide-header-close
      centered
    >
      <b-row>
        <b-col cols="12">
          <p>Are you sure you want to reject this application?</p>
        </b-col>
        <b-col cols="12" class="text-right">
          <b-button
            variant="primary"
            class="mr-1"
            id="rejectApplicationModalConfirm"
            @click="rejectApplicationModalConfirm"
            >Confirm</b-button
          >
          <b-button
            variant="outline-primary"
            id="rejectApplicationModalCancel"
            @click="rejectApplicationModalCancel"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import { BRow, BCol, BTab, BTabs } from "bootstrap-vue";
import Application from "./tabs/Application.vue";
import Overview from "./tabs/Overview.vue";
import Inventory from "./tabs/Inventory.vue";

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    Application,
    Overview,
    Breadcrumb,
    Inventory,
  },
};
</script>
