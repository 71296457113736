import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://api-unp.sacul.cloud/";

class UserService {
  async getUserApplication(username, useruuid) {
    var data = {
      username: username,
      useruuid: useruuid,
    };
    const response = await axios.post(API_URL + "user/getApplication", data, {
      headers: authHeader(),
    });
    return response.data;
  }

  async getUserProfile(username, useruuid) {
    var data = {
      username: username,
      useruuid: useruuid,
    };
    const response = await axios.post(API_URL + "user/getProfile", data, {
      headers: authHeader(),
    });
    return response.data;
  }

  async getUserDiscord(username, discordId) {
    var data = {
      username: username,
      discordId: discordId,
    };
    const response = await axios.post(API_URL + "user/getDiscord", data, {
      headers: authHeader(),
    });
    return response.data.data.Item;
  }

  async updateUserApplication(username, useruuid, data) {
    var data = {
      username: username,
      useruuid: useruuid,
      data: data,
    };
    const response = await axios.post(
      API_URL + "user/updateApplication",
      data,
      { headers: authHeader() }
    );
    return response.data;
  }

  async verifyPassword(username, password) {
    var data = {
      username: username,
      password: password,
    };
    const response = await axios
      .post(API_URL + "user/verifyPassword", data, {
        headers: authHeader(),
      })
      .catch((error) => {
        return error;
      });
    return response.data;
  }

  async updateContact(
    username,
    useruuid,
    firstName,
    lastName,
    email,
    dateofbirth,
    old_firstName,
    old_lastName,
    old_email,
    old_dateofbirth,
    discordId
  ) {
    var data = {
      username: username,
      useruuid: useruuid,
      firstName: firstName,
      lastName: lastName,
      email: email,
      dateofbirth: dateofbirth,
      old_firstName: old_firstName,
      old_lastName: old_lastName,
      old_email: old_email,
      old_dateofbirth: old_dateofbirth,
      discordid: discordId,
    };
    const response = await axios.post(API_URL + "user/updateContact", data, {
      headers: authHeader(),
    });
    return response.data;
  }

  async getWallets(username, useruuid) {
    var data = {
      username: username,
      useruuid: useruuid,
    };
    const response = await axios.post(API_URL + "user/getWallets", data, {
      headers: authHeader(),
    });
    return response.data;
  }

  async updateWallets(
    username,
    useruuid,
    walletMetamask,
    walletSolana,
    walletWax,
    walletRonin,
    old_walletMetamask,
    old_walletSolana,
    old_walletWax,
    old_walletRonin,
    discordId
  ) {
    var data = {
      username: username,
      useruuid: useruuid,
      walletMetamask: walletMetamask,
      walletRonin: walletRonin,
      walletSolana: walletSolana,
      walletWax: walletWax,
      old_walletMetamask: old_walletMetamask,
      old_walletRonin: old_walletRonin,
      old_walletSolana: old_walletSolana,
      old_walletWax: old_walletWax,
      discordid: discordId,
    };
    const response = await axios.post(API_URL + "user/updateWallets", data, {
      headers: authHeader(),
    });
    return response.data;
  }

  async updateUserGamesInterestedIn(username, useruuid, games) {
    var data = {
      username: username,
      useruuid: useruuid,
      games: games,
    };
    const response = await axios.post(
      API_URL + "user/updateUserGamesInterestedIn",
      data,
      { headers: authHeader() }
    );
    return response.data;
  }

  async getInventory(useruuid) {
    var data = {
      useruuid: useruuid,
    };
    const response = await axios.post(API_URL + "user/getInventory", data, {
      headers: authHeader(),
    });
    return response.data;
  }

  async updateInventory(useruuid, inventory_item, data) {
    var data = {
      useruuid: useruuid,
      inventory_item: inventory_item,
      data: data,
    };
    const response = await axios.post(API_URL + "user/updateInventory", data, {
      headers: authHeader(),
    });
    return response.data;
  }
}

export default new UserService();
