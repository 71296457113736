<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row>
        <b-col cols="12" xl="8" class="mb-32">
          <b-card id="invoice" class="card border-0 hp-invoice-card">
            <b-row align-h="between">
              <b-col cols="12" lg="6">
                <h2 class="mb-0">
                  {{ userApplication.username }}'s Application
                </h2>
              </b-col>

              <b-col cols="12" lg="6">
                <p class="hp-p1-body mb-0 text-right">
                  Application ID #{{ userApplication.applicationId || "N/A" }}
                </p>
              </b-col>
            </b-row>

            <div class="divider"></div>

            <b-row align-h="between">
              <b-col cols="12" md="4" class="pb-16 hp-print-info">
                <h3 style="margin-top: 5%; margin-bottom: 39px">
                  User Details
                </h3>
                <label class="hp-input-label">Full Name</label>
                <b-form-input
                  v-model="userApplication.fullName"
                  :options="statusOptions"
                  readonly
                  type="select"
                  label="Status"
                  class="mb-16"
                />

                <label class="hp-input-label">Country</label>
                <b-form-input
                  v-model="userApplication.country"
                  :options="statusOptions"
                  readonly
                  type="select"
                  label="Status"
                  class="mb-16"
                />
              </b-col>
              <b-col cols="12" md="4" class="pb-16 hp-print-info">
                <label class="hp-input-label">Email</label>
                <b-form-input
                  v-model="userApplication.email"
                  :options="statusOptions"
                  readonly
                  type="select"
                  label="Status"
                  class="mb-16"
                />
                <label class="hp-input-label">Portal Username</label>
                <b-form-input
                  v-model="userApplication.username"
                  :options="statusOptions"
                  readonly
                  type="select"
                  label="Status"
                  class="mb-16"
                />
                <label class="hp-input-label">Discord Username</label>
                <b-form-input
                  v-model="discordData.discordUsername"
                  :options="statusOptions"
                  readonly
                  type="select"
                  label="Status"
                  class="mb-16"
                />
              </b-col>
              <b-col cols="12" md="4" class="pb-16 hp-print-info">
                <label class="hp-input-label">Phone Number</label>
                <b-form-input
                  v-model="userApplication.phoneNumber"
                  :options="statusOptions"
                  readonly
                  type="select"
                  label="Status"
                  class="mb-16"
                />
                <label class="hp-input-label">Portal UserUUID</label>
                <b-form-input
                  v-model="userApplication.useruuid"
                  :options="statusOptions"
                  readonly
                  type="select"
                  label="Status"
                  class="mb-16"
                />

                <label class="hp-input-label">Discord ID</label>
                <b-form-input
                  v-model="discordData.discordId"
                  :options="statusOptions"
                  readonly
                  type="select"
                  label="Status"
                  class="mb-16"
                />
              </b-col>
            </b-row>

            <div class="divider"></div>
            <b-row>
              <b-col cols="12">
                <h3>Wallets</h3>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <p style="min-width: 70px" class="text-white text-left">
                      Metamask
                    </p>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userApplication.walletMetamask"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="copyWallet('metamask')"
                    >
                      <i class="remix-icon ri-file-copy-line"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <p style="min-width: 70px" class="text-white text-left">
                      Solana
                    </p>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userApplication.walletSolana"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="copyWallet('solana')"
                      ><i class="remix-icon ri-file-copy-line"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <p style="min-width: 70px" class="text-white text-left">
                      Ronin
                    </p>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userApplication.walletRonin"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="copyWallet('ronin')"
                      ><i class="remix-icon ri-file-copy-line"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <p style="min-width: 70px" class="text-white text-left">
                      Wax
                    </p>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userApplication.walletWax"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="copyWallet('wax')"
                      ><i class="remix-icon ri-file-copy-line"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <div class="divider"></div>

            <b-row align-h="between">
              <b-col cols="12" md="4" class="pb-16 hp-print-info">
                <h3 style="margin-top: 5%; margin-bottom: 39px">
                  Additional Details
                </h3>
                <p class="mb-6">
                  Game Knowledge: {{ userApplication.gameKnowledge }}
                </p>
                <p class="mb-6">
                  Crypto game before: {{ userApplication.nftGamesBefore }}
                </p>
                <p class="mb-6">
                  Scholar before: {{ userApplication.scholarBefore }}
                </p>
                <p class="mb-6">
                  If yes, scholar name:
                  {{ userApplication.scholarBeforeName || "Unspecified" }}
                </p>
                <p class="mb-6">
                  How did you hear about us?:
                  {{ userApplication.hearAboutUs }}
                </p>
                <p class="mb-6">
                  Referral Code:
                  {{ userApplication.referralDiscordUsername || "N/A" }}
                </p>
              </b-col>
              <b-col cols="12" md="4" class="pb-16 hp-print-info">
                <p
                  class="hp-text-color-black-100 hp-text-color-dark-0 hp-input-label"
                >
                  DISCORD ROLES:
                </p>
                <br />
                <nav>
                  <ul class="discordRolesList">
                    <li v-for="role in discordData.discordRoles" :key="role">
                      {{ role }}
                    </li>
                  </ul>
                </nav>
              </b-col>
              <!--<b-col cols="12" md="4" class="pb-16 hp-print-info">
                <p
                  class="hp-text-color-black-100 hp-text-color-dark-0 hp-input-label"
                >
                  GAMES INTERESTED IN:
                </p>
                <br />
                <nav>
                  <ul class="gamesList">
                    <li
                      v-for="game in userApplication.gamesInterestedIn"
                      :key="game"
                    >
                      {{ game }}
                    </li>
                  </ul>
                </nav>
              </b-col>-->
            </b-row>

            <div class="divider"></div>

            <b-row align-h="between">
              <b-col cols="12" md="12" class="pb-16 hp-print-info">
                <h3>Why do you want to be a Guild Member</h3>
                <b-form-textarea
                  v-model="userApplication.reasonWhy"
                  :rows="4"
                  readonly
                ></b-form-textarea>
              </b-col>
            </b-row>

            <div class="divider"></div>

            <b-row align-h="start" class="mr-0">
              <b-col cols="12" class="pb-16 hp-print-checkout">
                <h3>Private Notes</h3>
                <b-form-textarea
                  v-model="userApplication.privateNotes"
                  rows="6"
                  placeholder="Nothing to show here.."
                  class="mb-0 mt-24"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" xl="4" class="hp-print-none">
          <b-card class="border-0 mb-32">
            <h3>
              Status
              <b-badge
                style="
                  min-width: 100px;
                  background-color: #5c5c5c;
                  border-color: white;
                  color: white;
                "
                class="ml-16 hp-text-color-black-100 hp-text-color-dark-0"
                v-if="userApplication.status == 'submitted'"
                >New</b-badge
              >
              <b-badge
                variant="info"
                class="ml-16"
                v-if="userApplication.status == 'pending interview'"
                >Pending Interview</b-badge
              >
              <b-badge
                variant="danger"
                class="ml-16"
                v-if="userApplication.status == 'rejected'"
                >Rejected</b-badge
              >
              <b-badge
                variant="success"
                class="ml-16"
                v-if="userApplication.status == 'approved'"
                >Approved</b-badge
              >
              <b-badge
                variant="warning"
                class="ml-16"
                v-if="externalGames.length > 0"
              >
                External</b-badge
              >
            </h3>
            <b-row>
              <b-col cols="12" class="mt-12">
                <p>
                  Last update by:
                  {{ userApplication.lastUpdateBy || "N/A" }}
                </p>
                <p>
                  Last update at:
                  {{ userApplication.lastUpdateAt || "N/A" }}
                </p>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="border-0 mb-32">
            <b-row class="mb-32">
              <b-col cols="12">
                <h3>Application Control</h3>
              </b-col>
              <b-col cols="12" md="12">
                <b-form-select
                  v-model="userApplication.status"
                  :options="statusOptions"
                  class="mb-16"
                />
              </b-col>
              <b-col
                v-if="userApplication.status === 'rejected'"
                cols="12"
                md="12"
              >
                <b-form-textarea
                  class="mb-16"
                  v-model="userApplication.rejectionReason"
                  :rows="4"
                  placeholder="Please enter a reason for rejection."
                ></b-form-textarea>
              </b-col>
              <b-col
                v-if="userApplication.status === 'approved'"
                cols="12"
                md="12"
              >
                <b-tabs fill>
                  <b-tab title="Interested & Onboarded" active>
                    <b-row class="mt-16">
                      <b-col cols="6">
                        <p>User Interested in</p>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="option in options"
                            v-model="userApplication.gamesInterestedIn"
                            :key="option.value"
                            :value="option.value"
                            name="interestedIn"
                          >
                            {{ option.text }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <p>Onboard the user for</p>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="option in options"
                            v-model="userApplication.gamesOnboardedFor"
                            :key="option.value"
                            :value="option.value"
                            name="onboardedFor"
                          >
                            {{ option.text }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab title="External & Rejected/Revoked">
                    <b-row class="mt-16">
                      <b-col cols="6">
                        <p>External user for</p>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="option in options"
                            v-model="externalGames"
                            :key="option.value"
                            :value="option.value"
                            name="externalGames"
                          >
                            {{ option.text }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <p>Reject/Revoke user for</p>
                        <b-form-group>
                          <b-form-checkbox
                            v-for="option in options"
                            v-model="rejectedOrRevokedGames"
                            :key="option.value"
                            :value="option.value"
                            name="rejectedOrRevokedGames"
                          >
                            {{ option.text }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>

                <b-form-textarea
                  class="mb-16"
                  v-model="userApplication.gamesRejectionReason"
                  :rows="4"
                  placeholder="If you need to reject one or more game(s), please enter a reason."
                ></b-form-textarea>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  v-if="userApplication.status === 'approved'"
                  v-model="addRolesWithLilly"
                  name="isExternal"
                  class="mt-0 mb-16"
                >
                  Automatically add Discord role(s) with Lilly
                </b-form-checkbox>
                <b-button
                  variant="primary"
                  class="btn-block"
                  @click.prevent="updatedStatus"
                >
                  Update Status
                </b-button>
              </b-col>
              <b-col
                class="col-6 mt-24"
                v-if="userApplication.status === 'pending interview'"
              >
                <b-button
                  v-if="userApplication.status === 'pending interview'"
                  variant="success"
                  class="btn-block btn-ghost"
                  @click.prevent="lillyNotification"
                >
                  <img
                    src="https://cdn.discordapp.com/avatars/971788258033950720/1a075120a63df1c881339d5e3b4b0c81.png?size=1024"
                    alt="Lilly"
                    class="avatar avatar-sm mr-8"
                    style="max-height: 30px"
                  />
                  <span
                    >Add role & <br />
                    Send notification</span
                  >
                </b-button>
              </b-col>
              <b-col
                class="col-6 mt-24"
                v-if="userApplication.status === 'pending interview'"
              >
                <b-button
                  v-if="userApplication.status === 'pending interview'"
                  variant="success"
                  class="btn-block btn-ghost"
                  @click.prevent="lillyInterview"
                >
                  <img
                    src="https://cdn.discordapp.com/avatars/971788258033950720/1a075120a63df1c881339d5e3b4b0c81.png?size=1024"
                    alt="Lilly"
                    class="avatar avatar-sm mr-8"
                    style="max-height: 30px"
                  />
                  <span>Start Interview</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <!-- Approve Modal -->
    <b-modal
      id="approveApplicationModal"
      ref="approveApplicationModal"
      title="Approve Application"
      size="md"
      hide-footer
      hide-header-close
      centered
    >
      <b-row>
        <b-col cols="12">
          <p>Are you sure you want to approve this application?</p>
        </b-col>
        <b-col cols="12" class="text-right">
          <b-button
            variant="primary"
            class="mr-1"
            id="approveApplicationModalConfirm"
            @click="approveApplicationModalConfirm"
            >Confirm</b-button
          >
          <b-button
            variant="outline-primary"
            id="approveApplicationModalCancel"
            @click="approveApplicationModalCancel"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <!-- Reject Modal -->
    <b-modal
      id="rejectApplicationModal"
      ref="rejectApplicationModal"
      title="Reject Application"
      size="md"
      hide-footer
      hide-header-close
      centered
    >
      <b-row>
        <b-col cols="12">
          <p>Are you sure you want to reject this application?</p>
        </b-col>
        <b-col cols="12" class="text-right">
          <b-button
            variant="primary"
            class="mr-1"
            id="rejectApplicationModalConfirm"
            @click="rejectApplicationModalConfirm"
            >Confirm</b-button
          >
          <b-button
            variant="outline-primary"
            id="rejectApplicationModalCancel"
            @click="rejectApplicationModalCancel"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import {
  BRow,
  BCol,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BCard,
  BFormTextarea,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BFormSelect,
  BFormSelectOption,
  BFormSelectOptionGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BBadge,
  BModal,
  BTabs,
  BTab,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BButtonGroup,
} from "bootstrap-vue";
import adminServices from "../../../../../../services/admin.services";
import discordRolesNames from "./discordRoles.json";

export default {
  components: {
    BRow,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCard,
    BFormTextarea,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    Breadcrumb,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BFormSelectOption,
    BFormSelectOptionGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BBadge,
    BModal,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButtonGroup,
  },
  data() {
    return {
      privateNotesChanged: false,
      userApplication: null,
      discordData: null,
      externalGames: [],
      rejectedOrRevokedGames: [],
      addRolesWithLilly: true,
      statusOptions: [
        { value: "submitted", text: "Submitted" },
        { value: "pending interview", text: "Pending Interview" },
        { value: "approved", text: "Approved" },
        { value: "rejected", text: "Rejected" },
        { value: "migrated", text: "Migrated" },
      ],
      options: [
        { text: "Sunflower Land", value: "sunflowerland" },
        { text: "Million on Mars", value: "milliononmars" },
        { text: "Genopets", value: "genopets" },
        { text: "Legends of Venari", value: "legendsofvenari" },
        { text: "Honeyland", value: "honeyland" },
        { text: "Axie Infinity", value: "axieinfinity" },
        { text: "Illuvium", value: "illuvium" },
        { text: "Gods Unchained", value: "godsunchained" },
        { text: "Bigtime", value: "bigtime" },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    // get the username from the url
    const useruuid = this.$route.params.useruuid;

    await adminServices.getUserApplication(useruuid).then((response) => {
      if (!response.data) {
        this.$bvToast.toast("User not found!", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.userApplication = response.data;
      this.userApplication.fullName =
        this.userApplication.firstName + " " + this.userApplication.lastName;

      if (this.userApplication.lastUpdateAt) {
        this.userApplication.lastUpdateAt = new Date(
          this.userApplication.lastUpdateAt
        ).toLocaleString();
      } else {
        this.userApplication.lastUpdateAt = "N/A";
      }

      this.externalGames = response.data.externalGamesOnboardedFor || [];
      this.rejectedOrRevokedGames = response.data.rejectedOrRevokedGames || [];
    });

    await adminServices.getUserDiscordData(useruuid).then((response) => {
      if (!response.data) {
        this.$bvToast.toast("User not found!", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.discordData = response.data[0];

      for (var i in this.discordData.discordRoles) {
        this.discordData.discordRoles[i] =
          discordRolesNames[this.discordData.discordRoles[i]] || "Unknown Role";
      }
    });

    // transalte timestamp to date and time
    this.userApplication.createdAt = new Date(
      this.userApplication.createdAt
    ).toLocaleString();

    setTimeout(() => {
      this.isLoading = false;
    }, 2000);

    // Listen to change in userApplication.privateNotes
    // this.$watch(
    //   () => this.userApplication.privateNotes,
    //   (newVal, oldVal) => {
    //     if (newVal == oldVal) {
    //       this.privateNotesChanged = false;
    //     } else {
    //       this.privateNotesChanged = true;
    //     }
    //   }
    // );
  },
  methods: {
    async lillyInterview() {
      var userDiscordId = this.discordData.discordId;
      var userDiscordUsername = this.discordData.discordUsername;
      userDiscordUsername = userDiscordUsername.split("#")[0];
      var agentDiscordId = this.$store.state.auth.user.discordId;

      adminServices
        .lillyInterview(agentDiscordId, userDiscordId, userDiscordUsername)
        .then((res) => {
          ////console.log(res);
          if (res.success) {
            this.updatedStatus();
            this.$bvToast.toast("Interview Started!", {
              title: "Success",
              solid: true,
            });
          } else {
            this.$bvToast.toast(res.message, {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((err) => {
          ////console.log(err);
          this.$bvToast.toast("Error!", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },

    async createThread() {
      var userDiscordId = this.discordData.discordId;
      var userDiscordUsername = this.discordData.discordUsername;
      userDiscordUsername = userDiscordUsername.split("#")[0];
      var agentDiscordId = this.$store.state.auth.user.discordId;

      adminServices
        .createThread(agentDiscordId, userDiscordId, userDiscordUsername)
        .then((res) => {
          ////console.log(res);
          if (res.success) {
            this.$bvToast.toast("Thread created!", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          } else {
            this.$bvToast.toast(res.message, {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
    async lillyNotification() {
      var userDiscordId = this.discordData.discordId;
      var agentDiscordId = this.$store.state.auth.user.discordId;

      adminServices
        .sendInterviewNotification(userDiscordId, agentDiscordId)
        .then((response) => {
          this.updatedStatus();
          this.$bvToast.toast("Interview notification sent!", {
            title: "Success !",
            solid: true,
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            title: "Error !",
            variant: "danger",
            solid: true,
          });
        });
    },
    async getApplication() {
      await adminServices.getUserApplication(useruuid).then((response) => {
        this.userApplication = response.data[0];
        this.userApplication.fullName =
          this.userApplication.firstName + " " + this.userApplication.lastName;
      });
    },
    async updatedStatus() {
      var username = this.userApplication.username;
      var useruuid = this.userApplication.useruuid;
      var status = this.userApplication.status;
      var rejectionreason = this.userApplication.rejectionReason;
      var privateNotes = this.userApplication.privateNotes;
      var gamesInterestedIn = this.userApplication.gamesInterestedIn;
      var gamesOnboardedFor = this.userApplication.gamesOnboardedFor;
      var gamesRejectionReason = this.userApplication.gamesRejectionReason;
      var externalGames = this.externalGames || null;
      var rejectedOrRevokedGames = this.rejectedOrRevokedGames || null;
      var agentUsername = this.$store.state.auth.user.username;
      var timestamp = new Date().getTime();
      var addRolesWithLilly = this.addRolesWithLilly;
      var discordId = this.discordData.discordId;

      await adminServices
        .updateApplicationStatus(
          username,
          useruuid,
          status,
          rejectionreason,
          privateNotes,
          gamesOnboardedFor,
          gamesRejectionReason,
          externalGames,
          agentUsername,
          timestamp,
          gamesInterestedIn,
          rejectedOrRevokedGames,
          addRolesWithLilly,
          discordId
        )
        .then((response) => {
          this.$bvToast.toast("Application status updated to " + status, {
            title: "Success",
            solid: true,
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        });
    },
    copyWallet(type) {
      if (type == "metamask") {
        navigator.clipboard.writeText(this.userApplication.walletMetamask);
        this.$bvToast.toast("Copied to clipboard!", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } else if (type == "solana") {
        navigator.clipboard.writeText(this.userApplication.walletSolana);
        this.$bvToast.toast("Copied to clipboard!", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } else if (type == "ronin") {
        navigator.clipboard.writeText(this.userApplication.walletRonin);
        this.$bvToast.toast("Copied to clipboard!", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } else if (type == "wax") {
        navigator.clipboard.writeText(this.userApplication.walletWax);
        this.$bvToast.toast("Copied to clipboard!", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.discordRolesList {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;

  border: 1px solid #464545;
  border-radius: 10px;
  padding: 10px;
}

.gamesList {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;

  border: 1px solid #464545;
  border-radius: 10px;
  padding: 10px;
}
</style>
