<template>
  <b-row>
    <b-col class="col-12">
      <b-card class="mb-3 shadow border-0">
        <b-row>
          <b-col class="col-lg-2 col-12 mb-12 text-center">
            <div class="text-left mb-24">
              <b-badge
                style="
                  min-width: 100px;
                  background-color: #5c5c5c;
                  border-color: white;
                  color: white;
                "
                class="ml-16 hp-text-color-black-100 hp-text-color-dark-0"
                v-if="userApplication.status == 'submitted'"
                >New</b-badge
              >
              <b-badge
                variant="info"
                class="ml-16"
                v-if="userApplication.status == 'pending interview'"
                >Pending Interview</b-badge
              >
              <b-badge
                variant="danger"
                class="ml-16"
                v-if="userApplication.status == 'rejected'"
                >Rejected</b-badge
              >
              <b-badge
                variant="success"
                class="ml-16"
                v-if="userApplication.status == 'approved'"
                >Approved</b-badge
              >
              <b-badge variant="warning" class="ml-16" v-if="isExternal">
                External</b-badge
              >
            </div>
            <b-avatar
              :src="discordData.discordAvatar"
              class="mb-3"
              size="10rem"
              rounded="circle"
            ></b-avatar>
            <h4>{{ userApplication.fullName }}</h4>
            <a :href="'mailto:' + userApplication.email">{{
              userApplication.email
            }}</a>
          </b-col>
          <b-col class="col-lg-4 col-12 mb-12"> </b-col>
          <b-col class="col-lg-4 col-12 mb-12"> </b-col>
          <b-col class="col-lg-2 col-12 mb-12">
            <b-button
              variant="warning"
              class="btn-block btn-ghost"
              @click="passwordChange(userApplication.id)"
              disabled
              >Password Reset</b-button
            >
            <b-button
              variant="danger"
              class="btn-block btn-ghost"
              @click="
                blockService(discordData.unpUseruuid, discordData.unpUsername)
              "
              >{{
                userApplication.isBlocked ? "Unblock" : "Block"
              }}
              User</b-button
            >
            <b-button
              variant="danger"
              class="btn-block"
              @click="deleteUser(userApplication.id)"
              disabled
              >Delete User</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow, BCard, BAvatar, BBadge, BButton } from "bootstrap-vue";
import adminServices from "../../../../../../services/admin.services";
import discordRolesNames from "./discordRoles.json";
import countryList from "./countryList.json";

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BAvatar,
    BBadge,
    BButton,
  },
  data() {
    return {
      isLoading: true,
      userApplication: null,
      discordData: null,
      isExternal: false,
    };
  },
  async mounted() {
    const useruuid = this.$route.params.useruuid;

    await adminServices.getUserApplication(useruuid).then((response) => {
      if (!response.data) {
        this.$bvToast.toast("User not found!", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.userApplication = response.data;
      this.userApplication.fullName =
        this.userApplication.firstName + " " + this.userApplication.lastName;

      if (this.userApplication.lastUpdateAt) {
        this.userApplication.lastUpdateAt = new Date(
          this.userApplication.lastUpdateAt
        ).toLocaleString();
      } else {
        this.userApplication.lastUpdateAt = "N/A";
      }

      this.externalGames = response.data.externalGamesOnboardedFor || [];
      this.rejectedOrRevokedGames = response.data.rejectedOrRevokedGames || [];

      if (response.data.externalGamesOnboardedFor) {
        if (response.data.externalGamesOnboardedFor.length > 0) {
          this.isExternal = true;
        }
      }
    });

    await adminServices.getUserDiscordData(useruuid).then((response) => {
      if (!response.data) {
        this.$bvToast.toast("User not found!", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.discordData = response.data[0];

      for (var i in this.discordData.discordRoles) {
        this.discordData.discordRoles[i] =
          discordRolesNames[this.discordData.discordRoles[i]] || "Unknown Role";
      }
    });

    // transalte timestamp to date and time
    this.userApplication.createdAt = new Date(
      this.userApplication.createdAt
    ).toLocaleString();

    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  methods: {
    async blockService(useruuid, username) {
      if (this.userApplication.isBlocked) {
        await adminServices.unblockUser(useruuid, username).then((response) => {
          if (response.success) {
            this.$bvToast.toast("User unblocked!", {
              title: "Success",
              solid: true,
            });
            this.userApplication.isBlocked = false;
          } else {
            this.$bvToast.toast("User not found!", {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          }
        });
      } else {
        await adminServices.blockUser(useruuid, username).then((response) => {
          if (response.success) {
            this.$bvToast.toast("User blocked!", {
              title: "Success",
              solid: true,
            });
            this.userApplication.isBlocked = true;
          } else {
            this.$bvToast.toast("User not found!", {
              title: "Error",
              variant: "danger",
              solid: true,
            });
          }
        });
      }
    },
  },
};
</script>
